<template>
	<b-card no-body>
		<b-row>
			<b-col>
				<b-table
					:items="competitionData"
					:fields="fields"
					primary-key="id"
					:sort-by.sync="sortBy"
					:sort-desc.sync="isSortDirDesc"
					class="position-relative"
					show-empty
					striped
					empty-text="No se encontraron registros coincidentes"
				>
					<template #cell(competition_state)="data">
						<div class="text-nowrap">
							<b-badge
								:variant="`light-${data.item.competition_state_color}`"
							>{{ data.item.competition_state }}</b-badge>
						</div>
					</template>

					<template #cell(documents)="data">
						<div class="text-nowrap">
							<b-button-upload
								entity="COMPETITIONS"
								:entity-id="data.item.id"
								:is-disabled="true"
								:competition-props="{ isSpecific: true }"
								:modal-props="{ title: 'DOCUMENTOS ESPECÍFICOS' }"
								:button-props="{ text: 'VER DOCUMENTOS', onlyIcon: true }"
							/>
						</div>
					</template>

					<template #cell(finished_at)="data">
						<div
							class="text-nowrap"
						>{{ data.item.finished_at ? moment(data.item.finished_at).format("DD-MM-YYYY HH:mm") : "-" }}</div>
					</template>

					<template #cell(actions)="data">
						<b-button
							variant="outline-primary"
							:to="{ 
								name: 'tenders-bidder-review', 
								params: {
									id: data.item.tender_id,
									competition_review_id: data.item.competition_review_id
								}
					}"
						>{{ isDisabled || data.item.status || !isAssigned ? 'Ver Propuestas' : 'Evaluar Propuestas' }}</b-button>
					</template>
				</b-table>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import { ref } from "@vue/composition-api"
import BButtonUpload from "@core/components/b-button-upload/BButtonUpload.vue"
import moment from "moment"

export default {
	components: {
		BButtonUpload
	},
	data() {
		return {
			moment
		}
	},
	props: {
		isDisabled: {
			type: Boolean,
			default: false
		},
		competitionData: {
			type: Array,
			required: true
		},
		isAssigned: {
			type: Boolean,
			required: true
		}
	},
	setup(props) {
		const perPage = ref(10)
		const currentPage = ref(1)
		const perPageOptions = [10, 25, 50, 100]
		const sortBy = ref("id")
		const isSortDirDesc = ref(false)

		const styleColumns = {
			thClass: "text-center",
			tdClass: "text-center"
		}

		const items = ref([])

		const fields = ref([
			{ key: "bidder_name", label: "POSTOR", thClass: styleColumns.thClass },
			{
				key: "competition_state",
				label: "ESTADO DE LICITACIÓN",
				...styleColumns
			},
			{ key: "finished_at", label: "FECHA DE ENVÍO", ...styleColumns },
			{ key: "documents", label: "DOCUMENTOS ESPECÍFICOS", ...styleColumns },
			{ key: "actions", label: "OPCIONES", ...styleColumns }
		])

		return {
			items,
			fields,

			perPage,
			currentPage,
			perPageOptions,
			sortBy,
			isSortDirDesc
		}
	}
}
</script>
