import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		createSchedule(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post('/tender-schedules', payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		updateSchedule(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/tender-schedules/${id}`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}