import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchTenders(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/tenders', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		fetchTender(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/tenders/${id}/edit`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		fetchTenderReview(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/tenders/${id}/review`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		createTender(ctx, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post('/tenders', payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		updateTender(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/tenders/${id}`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}