export const RequestStates = {
	EN_EDICION: 1,
	EN_APROBACION: 2,
	APROBADO: 3,
	RECHAZADO: 4,
	CADUCADO: 5,
}

export const TenderStates = {
	EN_EDICION: 1,
	POR_EMPEZAR: 2,
	EN_CURSO: 3,
	EN_REVISION: 4,
	FINALIZADO: 5,
}

export const Stages = {
	INVITACION: 1,
	VISITA: 2,
	CONSULTA: 3,
	ABSOLUCION_CONSULTA: 4,
	RECEPCION_PROPUESTA: 5,
	EVALUACION_PROPUESTA: 6,
	APROBACION: 7,
	FIRMA: 8
}

export const CompetitionStates = {
	PENDIENTE: 1,
	DECLINADO: 2,
	EN_CURSO: 3,
	EN_REVISION: 4,
	FINALIZADO: 5,
	RETIRADO: 6
}

export const ProposalTypes = {
	ECONOMICA: 1,
	SIG: 2,
	TECNICA: 3,
}

export const FileTypes = {
	ESPECIFICO: 1,
	GENERAL: 2,
	VISITA: 3,
	CONSULTA: 4,
	ABSOLUCION: 5,
	SIG_TECNICA: 6,
	ECONOMICA: 7,
	PLANTILLA: 8,
	PLANTILLA_POSTOR: 9,
	EVIDENCIA: 10,
	INTERNO: 11,
}