import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchCompetitionReviews(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/competition-reviews/', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}