
<template>
	<div>
		<b-card-countdown
			stage="evaluación de propuestas"
			:stage-closed="stageClosed"
			:deadline="deadline"
			:start-date="startDate"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<b-card v-if="!hasReviewData">
			<div class="demo-spacing-0">
				<b-alert
					variant="info"
					show
				>
					<div class="alert-body">
						<span>
							<strong>¡Datos pendientes!</strong> Se cargará la información cuando los postores envíen sus propuestas.
						</span>
					</div>
				</b-alert>
			</div>
		</b-card>

		<b-card v-else>
			<b-tabs align="left">
				<b-tab
					name="info"
					active
				>
					<template #title>
						<span class="d-none d-sm-inline">Revisión de Propuestas</span>
					</template>

					<!-- v-if="competitionData.length > 0" -->
					<tab-proposals
						:tender-id="tenderId"
						:is-disabled="isDisabled"
						:competition-data="competitionData"
						:is-assigned="isAssigned"
					/>
				</b-tab>

				<b-tab name="matrix">
					<template #title>
						<span class="d-none d-sm-inline">Matriz de Evaluación</span>
					</template>

					<!-- :tender-data="tenderData" -->
					<!-- v-if="Object.keys(evaluationTables).length > 0" -->
					<tab-matrix :evaluation-data="evaluationData" />
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
import { Stages, TenderStates } from "@core/utils/data"
import { ref, onUnmounted, onBeforeMount } from "@vue/composition-api"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import TabMatrix from "./matrix/TabMatrix.vue"
import TabProposals from "./proposals/TabCompetitionProposals.vue"
import evaluationStoreModule from "./evaluationStoreModule"
import useNotifications from "@notifications"
import store from "@/store"

export default {
	components: {
		BCardCountdown,
		TabMatrix,
		TabProposals
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		scheduleData: {
			type: Array,
			required: true,
			default: () => []
		},
		tenderData: {
			type: Object,
			required: true,
			default: () => {}
		},
		isAssigned: {
			type: Boolean,
			required: true
		}
	},
	setup(props) {
		const { toastNotification } = useNotifications()

		const { FINALIZADO, EN_REVISION } = TenderStates

		const competitionData = ref([])
		const evaluationData = ref({})
		const scheduleData = ref(props.scheduleData)
		const tenderData = ref(props.tenderData)
		const tablesData = ref({})
		const deadline = ref(null)
		const startDate = ref(null)
		const isDisabled = ref(false)
		const stageClosed = ref(false)
		const hasReviewData = ref(false)

		const EVALUATION_APP_STORE_MODULE_NAME = "app-evaluation"

		// REGISTER MODULE
		if (!store.hasModule(EVALUATION_APP_STORE_MODULE_NAME))
			store.registerModule(
				EVALUATION_APP_STORE_MODULE_NAME,
				evaluationStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(EVALUATION_APP_STORE_MODULE_NAME))
				store.unregisterModule(EVALUATION_APP_STORE_MODULE_NAME)
		})

		const getData = () => {
			store
				.dispatch("app-evaluation/fetchCompetitionReviews", {
					tender_id: props.tenderId
				})
				.then((response) => {
					const { items, tables } = response.data.competitionReviews
					if (items.length > 0) {
						hasReviewData.value = true
						competitionData.value = items
						evaluationData.value = tables
					}
				})
				.catch((error) => {
					const message = error.message || error.response.data.message
					toastNotification(message, false)
				})
		}

		const hidden = ref(false)

		onBeforeMount(() => {
			setData()
			getData()
		})

		if (tenderData.value.tender_state_id !== EN_REVISION) {
			stageClosed.value = true
			isDisabled.value = true
		}

		const setData = () => {
			const evaluation = scheduleData.value.find(
				(item) => parseInt(item.tender_stage_id) === Stages.EVALUACION_PROPUESTA
			)
			deadline.value = evaluation.actual_end_date || ""
			startDate.value = evaluation.actual_start_date || ""
		}

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}
		const handleNotYetStarted = (value) => {
			if (tenderData.value.tender_state_id !== EN_REVISION) {
				stageClosed.value = true
				isDisabled.value = true
			} else {
				isDisabled.value = value
				stageClosed.value = value
			}
		}

		return {
			deadline,
			startDate,
			hidden,
			isDisabled,
			stageClosed,
			competitionData,
			evaluationData,
			hasReviewData,

			handleTimeFinished,
			handleNotYetStarted
		}
	}
}
</script>