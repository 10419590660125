
<template>
	<component :is="'div'">
		<!-- Alert: No item found -->
		<b-alert
			variant="danger"
			:show="tenderData === undefined"
		>
			<h4 class="alert-heading">Error al obtener los datos de la licitación</h4>
			<div class="alert-body">
				No se encontró ninguna licitación con este id. Verificar en la
				<b-link
					class="alert-link"
					:to="{ name: 'tenders-list'}"
				>Lista de licitaciones</b-link>para buscar otra licitación.
			</div>
		</b-alert>

		<b-tabs
			pills
			v-model="activeTab"
			v-if="tenderData"
		>
			<!-- Tab: Schedules -->
			<b-tab name="schedules">
				<template #title>
					<feather-icon
						icon="CalendarIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Cronograma</span>
				</template>

				<tender-edit-tab-schedules
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:schedule-data="schedulesData"
					:is-published="tenderData.is_published"
					:is-assigned="tenderData.is_assigned"
					@update-schedule="updateSchedule"
				/>
			</b-tab>

			<!-- Tab: Invitations -->
			<b-tab name="invitations">
				<template #title>
					<feather-icon
						icon="BookmarkIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Invitación</span>
				</template>

				<tender-edit-tab-invitations
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:request-id="tenderData.request_id"
					:information-id="parseInt(tenderData.information_id)"
					:tender-data="tenderData"
					:schedule-data="schedulesData"
					:is-published="tenderData.is_published"
					:is-assigned="tenderData.is_assigned"
				/>
			</b-tab>

			<!-- Tab: Consultations -->
			<b-tab
				name="consultations"
				:disabled="[EN_EDICION].includes(tenderData.tender_state_id)"
			>
				<template #title>
					<feather-icon
						:icon="[EN_EDICION].includes(tenderData.tender_state_id) ? 'LockIcon' : 'InboxIcon'"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Consultas</span>
				</template>

				<tender-edit-tab-consultations
					v-if="![EN_EDICION].includes(tenderData.tender_state_id)"
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:tender-data="tenderData"
					:schedule-data="tenderData.tender_schedules"
					:is-assigned="tenderData.is_assigned"
				/>
			</b-tab>

			<!-- Tab: Evaluations -->
			<b-tab
				name="evaluations"
				:disabled="[EN_EDICION].includes(tenderData.tender_state_id)"
			>
				<template #title>
					<feather-icon
						:icon="[EN_EDICION].includes(tenderData.tender_state_id) ? 'LockIcon' : 'SearchIcon'"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Evaluación Propuestas</span>
				</template>

				<tender-edit-tab-evaluations
					v-if="![EN_EDICION].includes(tenderData.tender_state_id)"
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:schedule-data="tenderData.tender_schedules"
					:competition-data="tenderData.competitions"
					:tender-data="tenderData"
					:is-assigned="tenderData.is_assigned"
				/>
			</b-tab>

			<!-- Tab: Approvals -->
			<b-tab
				name="approvals"
				:disabled="[EN_EDICION].includes(tenderData.tender_state_id)"
			>
				<template #title>
					<feather-icon
						:icon="[EN_EDICION].includes(tenderData.tender_state_id) ? 'LockIcon' : 'AwardIcon'"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Aprobación y Selección</span>
				</template>

				<tender-edit-tab-approvals
					v-if="![EN_EDICION].includes(tenderData.tender_state_id)"
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:tender-winner-id="tenderData.tender_winner.id"
					:tender-data="tenderData"
					:schedule-data="tenderData.tender_schedules"
					:is-assigned="tenderData.is_assigned"
				/>
			</b-tab>
		</b-tabs>
	</component>
</template>

<script>
import { formatDateToString } from "@core/utils/filter"
import { Stages } from "@core/utils/data"
import { TenderStates } from "@core/utils/data"
import TenderEditTabSchedules from "./schedules/TenderEditTabSchedules.vue"
import TenderEditTabInvitations from "./invitations/TenderEditTabInvitations.vue"
import TenderEditTabConsultations from "./consultations/TenderEditTabConsultations.vue"
import TenderEditTabEvaluations from "./evaluations/TenderEditTabEvaluations.vue"
import TenderEditTabApprovals from "./approvals/TenderEditTabApprovals.vue"
import tenderStoreModule from "../tenderStoreModule"
import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api"
import useNotifications from "@notifications"
import router from "@/router"
import store from "@/store"
import moment from "moment"

export default {
	components: {
		TenderEditTabSchedules,
		TenderEditTabInvitations,
		TenderEditTabConsultations,
		TenderEditTabEvaluations,
		TenderEditTabApprovals
	},
	setup() {
		const { toastNotification } = useNotifications()
		const { VISITA } = Stages
		const { EN_EDICION, EN_REVISION, FINALIZADO } = TenderStates

		const activeTab = computed({
			set(val) {
				let query = { ...router.currentRoute.query }
				query.tab = val
				router.replace({ query: query })
			},
			get() {
				return parseInt(router.currentRoute.query.tab)
			}
		})
		// REFS
		const tenderData = ref(null)
		const schedulesData = ref([])

		const TENDER_APP_STORE_MODULE_NAME = "app-tender"

		// REGISTER MODULE
		if (!store.hasModule(TENDER_APP_STORE_MODULE_NAME))
			store.registerModule(TENDER_APP_STORE_MODULE_NAME, tenderStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(TENDER_APP_STORE_MODULE_NAME))
				store.unregisterModule(TENDER_APP_STORE_MODULE_NAME)
		})

		store
			.dispatch("app-tender/fetchTender", {
				id: router.currentRoute.params.id
			})
			.then((response) => {
				tenderData.value = response.data.tender
				schedulesData.value = response.data.tender.tender_schedules
			})
			.catch((error) => {
				if (error.response.status === 404) {
					tenderData.value = undefined
				}
				toastNotification(error.response.data.message, false)
				router.push({ name: "tenders-list" })
			})

		const updateSchedule = ({ index, payload }) => {
			schedulesData.value[index].days = payload.days
			schedulesData.value[index].actual_days = payload.actual_days
			schedulesData.value[index].days_difference = payload.days_difference
			schedulesData.value[index].start_date = formatDateToString(
				payload.start_date
			)
			schedulesData.value[index].end_date = formatDateToString(payload.end_date)
			schedulesData.value[index].actual_start_date = formatDateToString(
				payload.actual_start_date
			)
			schedulesData.value[index].actual_end_date = formatDateToString(
				payload.actual_end_date
			)
		}

		return {
			moment,

			activeTab,
			tenderData,
			schedulesData,

			updateSchedule,

			EN_REVISION,
			FINALIZADO,
			EN_EDICION
		}
	}
}
</script>