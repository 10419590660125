<template>
	<section>
		<b-card>
			<app-collapse type="margin">
				<app-collapse-item
					title="DATOS GENERALES"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col
							cols="12"
							class="my-1"
						>
							<b-alert
								variant="info"
								show
							>
								<h6 class="alert-heading">
									<feather-icon
										icon="InfoIcon"
										class="mr-50"
									/>
									<span
										class="small"
									>La ejecución del servicio se dará acabo en la(s) unidad(es) seleccionada(s)</span>
								</h6>
							</b-alert>
						</b-col>
						<!-- TENDER TITLE -->
						<b-col
							cols="12"
							class="mb-1"
						>
							<label for="name">
								<strong>OBJETO DEL CONTRATO</strong>
							</label>
							<b-form-input
								id="name"
								placeholder="LICITACION DE PRUEBA"
								:value="requestData.title"
								disabled
							/>
						</b-col>

						<!-- CONTRACT TYPE -->
						<b-col
							cols="12"
							md="5"
							class="mb-1"
						>
							<label for="contractType">
								<strong>TIPO DE CONTRATO</strong>
							</label>
							<v-select
								id="contractType"
								:value="requestData.contract_type"
								:options="options.contractTypes"
								disabled
								readonly
							/>
						</b-col>

						<!-- LOCATION -->
						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="location">
								<strong>EJECUCIÓN DE SERVICIO</strong>
							</label>
							<v-select
								id="location"
								:value="requestData.locations"
								:options="options.locations"
								multiple
								disabled
								readonly
							/>
						</b-col>

						<b-col
							cols="12"
							md="3"
							class="mb-1"
						>
							<label for="tender_type">
								<strong>TIPO DE LICITACIÓN</strong>
							</label>
							<v-select
								id="tender_type"
								:value="requestData.tender_type"
								:options="options.tenderTypes"
								disabled
								readonly
							/>
						</b-col>

						<!-- DESCRIPTION -->
						<b-col cols="12">
							<label for="description">
								<strong>DESCRIPCIÓN</strong>
							</label>
							<b-form-textarea
								id="description"
								:value="requestData.description"
								placeholder="detalle"
								disabled
							></b-form-textarea>
						</b-col>
					</b-row>
				</app-collapse-item>

				<app-collapse-item
					title="INVITACIÓN"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col>
							<h6>Estimados Señores,</h6>
							<b-form-textarea
								id="description"
								v-model="tenderData.invitation_message"
								placeholder="Ingrese texto de invitación..."
								:disabled="isPublished ? true : false"
								:readonly="isPublished ? true : false"
								rows="5"
								trim
							/>
						</b-col>
					</b-row>
				</app-collapse-item>

				<app-collapse-item
					title="CRONOGRAMA"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col cols="6">
							<b-table
								class="table-sm"
								ref="refScheduleTable"
								responsive
								:fields="fields"
								:items="scheduleData.filter( item => parseInt(item.tender_stage_enabled))"
								bordered
							>
								<template #cell(actual_start_date)="data">
									<div
										class="text-nowrap"
									>{{ data.item.actual_start_date ? moment(data.item.actual_start_date).format("DD-MM-YYYY H:mm") : 'DD-MM-YYYY' }}</div>
								</template>
								<template #cell(actual_end_date)="data">
									<div
										class="text-nowrap"
									>{{ data.item.actual_end_date ? moment(data.item.actual_end_date).format("DD-MM-YYYY H:mm") : 'DD-MM-YYYY' }}</div>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</app-collapse-item>
				<app-collapse-item
					title="DOCUMENTOS"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col cols="6">
							<label>
								<strong>DOCUMENTOS GENERALES</strong>
							</label>
							<b-button-upload
								entity="REQUESTS"
								:entity-id="requestId"
								:is-disabled="isDisabled || !isAssigned"
								:request-props="{ isGeneral: true }"
								:modal-props="{ title: 'DOCUMENTOS GENERALES' }"
								:button-props="{ text: isDisabled ? 'VER DOCUMENTOS' : 'CARGAR DOCUMENTOS', block : true }"
							/>
						</b-col>

						<b-col cols="6">
							<label>
								<strong>DOCUMENTOS INTERNOS</strong>
							</label>
							<b-button-upload
								entity="TENDERS"
								:entity-id="tenderId"
								:is-disabled="!isAssigned"
								:tender-props="{ isInternal: true }"
								:modal-props="{ title: 'DOCUMENTOS INTERNOS' }"
								:button-props="{ text: isAssigned ? 'CARGAR DOCUMENTOS' : 'VER DOCUMENTOS', block : true }"
							/>
						</b-col>
					</b-row>
				</app-collapse-item>
			</app-collapse>

			<b-row>
				<b-col class="mt-3">
					<b-button-loading
						v-if="!isPublished && isAssigned"
						text="ENVIAR INVITACIONES"
						variant="relief-primary"
						:block="true"
						size="lg"
						:processing="processing"
						@process-action="sendInvitations"
					/>
				</b-col>
			</b-row>
		</b-card>
	</section>
</template>

<script>
import { TenderStates } from "@core/utils/data"
import { ref, onBeforeMount } from "@vue/composition-api"
import { VBTooltip } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import BButtonUpload from "@core/components/b-button-upload/BButtonUpload.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import useNotifications from "@notifications"
import VueSweetalert2 from "vue-sweetalert2"
import moment from "moment"
import router from "@/router"
import axios from "@axios"
import vSelect from "vue-select"
import Vue from "vue"

Vue.use(VueSweetalert2)

export default {
	components: {
		AppCollapse,
		AppCollapseItem,
		BButtonUpload,
		BButtonLoading,

		vSelect
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	data() {
		return {
			moment
		}
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		requestId: {
			type: Number,
			required: true
		},
		informationId: {
			type: Number,
			required: true
		},
		isPublished: {
			type: Boolean,
			required: true
		},
		scheduleData: {
			type: Array,
			required: true,
			default: () => ({})
		},
		tenderData: {
			type: Object,
			required: true,
			default: () => ({})
		},
		isAssigned: {
			type: Boolean,
			required: true
		}
	},
	setup(props) {
		const { swalNotification, toastNotification } = useNotifications()

		const { FINALIZADO } = TenderStates
		const options = ref({})
		const tenderId = ref(props.tenderId)
		const tenderData = ref(props.tenderData)
		const requestData = ref({})
		const processing = ref(false)
		const fields = ref([])
		const isDisabled = ref(false)

		onBeforeMount(async () => {
			await getSelectFilters()
			await getInformationData()
			setStageDataTable()
		})

		if (tenderData.value.tender_state_id === FINALIZADO) {
			isDisabled.value = true
		}

		const getSelectFilters = async () => {
			const contractTypes = await axios.get("/selectors/contract-types")
			const locations = await axios.get("/selectors/locations")
			const tenderTypes = [
				{ label: "NORMAL", value: "NORMAL" },
				{ label: "DIRECTA", value: "DIRECTA" }
			]
			options.value = {
				...contractTypes.data,
				...locations.data,
				tenderTypes
			}
		}

		const getInformationData = async () => {
			try {
				const response = await axios.get(
					`/request-information/${props.informationId}/edit`
				)
				setData(response.data.requestInformation)
			} catch (error) {
				toastNotification(error.response?.data?.message, false)
			}
		}
		const setData = (data) => {
			const contract_type = options.value.contractTypes.find(
				(item) => item.value === data.contract_type_id
			)
			const tender_type = options.value.tenderTypes.find(
				(item) => item.value === data.tender_type
			)

			const locationIds = data.locations.map((item) => item.value)
			const locations = options.value.locations.filter((item) =>
				locationIds.includes(item.value)
			)
			requestData.value = {
				title: data.title,
				description: data.description,
				tender_type,
				contract_type,
				locations
			}
		}

		const setStageDataTable = () => {
			const styleColumns = { thClass: "text-center", tdClass: "text-center" }

			fields.value = [
				{
					key: "tender_stage_name",
					label: "Etapa",
					thClass: styleColumns.thClass
				},
				{ key: "actual_start_date", label: "Fecha Inicial", ...styleColumns },
				{ key: "actual_end_date", label: "Fecha Límite", ...styleColumns }
			]
		}

		const sendInvitations = async () => {
			if (!tenderData.value.invitation_message) {
				toastNotification("Por favor, ingrese un mensaje de invitación", false)
				return
			}

			Vue.swal({
				title: "¿Está seguro(a) de enviar las invitaciones?",
				html: "<small>¡Recuerde que luego no podrá realizar ninguna modificación!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, enviar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then(async (result) => {
				if (!result.value) return

				Vue.swal.fire({
					title: "Procesando...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Vue.swal.showLoading()
					}
				})

				processing.value = true

				try {
					const payload = {
						tender_id: tenderId.value,
						invitation_message: tenderData.value.invitation_message
					}
					const response = await axios.put("/tender-invitations/send", payload)
					/* swalNotification(response.data.message) */
					toastNotification(response.data.message)
					router.push({ name: "tenders-list" })
				} catch (error) {
					processing.value = false
					const message =
						error.response.data.message ||
						"Ocurrió un error al enviar las invitaciones"
					/* swalNotification(message, false) */
					toastNotification(message, false)
				} finally {
					Vue.swal.close()
					processing.value = false
				}
			})
		}

		return {
			// REFS
			options,
			fields,
			processing,
			requestData,
			isDisabled,

			// METHODS
			sendInvitations
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>